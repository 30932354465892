import axios from "@/api/http";
const BASE_URL = process.env.VUE_APP_GENERAL_URL;

/**
 * 总控-运营中心-复核缺货管理-列表
 * @param {*} params
 * @returns
 */
export function getQcRefundLackList(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/qc/refund/lack/list`,
    data: params,
  });
}

/**
 * 总控-运营中心-复核缺货管理-列表-发券金额获取
 * @param {*} params
 * @returns
 */
export function getQcRefundVerifyCoupon(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/qc/refund/verify/coupon`,
    data: params,
  });
}

/**
 * 总控-运营中心-复核缺货管理-列表-发券
 * @param {*} params
 * @returns
 */
export function postQcRefundSendCoupon(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/qc/refund/send/coupon`,
    data: params,
  });
}

/**
 * 总控-运营中心-复核缺货管理-列表-扣款
 * @param {*} params
 * @returns
 */
export function postQcRefundDeductionDone(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/qc/refund/deduction/done`,
    data: params,
  });
}

/**
 * 总控-运营中心-复核缺货管理-列表-编辑
 * @param {*} params
 * @returns
 */
export function postQcRefundDeductionUpdate(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/qc/refund/deduction/update`,
    data: params,
  });
}

/**
 * 总控-运营中心-扣款类型下拉
 * @param {*} params
 * @returns
 */
export function getDeductionTypeList(params = {}) {
  return axios({
    method: "GET",
    url: `${BASE_URL}/logistics/other/deduction/typeList`,
    params,
  });
}

/**
 * 总控-运营中心-供应商扣款类型下拉
 * @param {*} params
 * @returns
 */
export function getSupplierDeductionTypeList(params = {}) {
  return axios({
    method: "GET",
    url: `${BASE_URL}/supplier/other/deduction/typeList`,
    params,
  });
}

/**
 * 总控-运营中心-供应商下拉
 * @param {*} params
 * @returns
 */
export function getSupplierSelectList(params = {}) {
  return axios({
    method: "GET",
    url: `${BASE_URL}/supplier/select/list`,
    params,
  });
}

/**
 * 总控-运营中心-业务下拉
 * @param {*} params
 * @returns
 */
export function postBuyerSelectList(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/buyer/select/list`,
    params,
  });
}

/**
 * 总控-运营中心-城市仓扣款管理-列表
 * @param {*} params
 * @returns
 */
export function getLogisticsDeductionList(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/logistics/other/deduction/list`,
    data: params,
  });
}

/**
 * 总控-运营中心-城市仓扣款管理-列表-新增
 * @param {*} params
 * @returns
 */
export function postLogisticsDeductionSave(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/logistics/other/deduction/save`,
    data: params,
  });
}
/**
 * 总控-运营中心-城市仓扣款管理-列表-批量新增
 * @param {*} params
 * @returns
 */
export function postLogisticsDeductionbatchSave(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/logistics/other/deduction/batchSave`,
    data: params,
  });
}

/**
 * 总控-运营中心-城市仓扣款管理-列表-编辑
 * @param {*} params
 * @returns
 */
export function postLogisticsDeductionEdit(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/logistics/other/deduction/edit`,
    data: params,
  });
}

/**
 * 总控-运营中心-城市仓扣款管理-列表-审核
 * @param {*} params
 * @returns
 */
export function postLogisticsDeductionAudit(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/logistics/other/deduction/examine`,
    data: params,
  });
}

/**
 * 总控-运营中心-城市仓扣款管理-列表-作废
 * @param {*} params
 * @returns
 */
export function postLogisticsDeductionCancel(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/logistics/other/deduction/cancel`,
    data: params,
  });
}

/**
 * 总控-运营中心-供应商扣款管理-列表
 * @param {*} params
 * @returns
 */
export function getSupplierDeductionList(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/supplier/other/deduction/list`,
    data: params,
  });
}

/**
 * 总控-运营中心-供应商扣款管理-列表-新增
 * @param {*} params
 * @returns
 */
export function postSupplierDeductionSave(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/supplier/other/deduction/save`,
    data: params,
  });
}

/**
 * 总控-运营中心-供应商扣款管理-列表-审核
 * @param {*} params
 * @returns
 */
export function postSupplierDeductionAudit(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/supplier/other/deduction/examine`,
    data: params,
  });
}

/**
 * 总控-运营中心-供应商扣款管理-列表-删除
 * @param {*} params
 * @returns
 */
export function postSupplierDeductionDel(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/supplier/other/deduction/del`,
    data: params,
  });
}

/**
 * 总控-运营中心-仓主吨位变更记录
 * @param {*} params
 * @returns
 */
export function postLogisticsTonnageChangeList(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/logistics/tonnageChangeList`,
    data: params,
  });
}

/**
 * 总控-第三方服务费列表
 * @param {*} params
 * @returns
 */
export function postSettlementSupplierServiceChargesSkuList(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/settlement/supplier/serviceCharges/sku/list`,
    data: params,
  });
}

/**
 * 总控-第三方服务费明细列表
 * @param {*} params
 * @returns
 */
export function postSettlementSupplierServiceChargesOrderList(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/settlement/supplier/serviceCharges/order/list`,
    data: params,
  });
}

/**
 * 总控-平台销售服务费列表
 * @param {*} params
 * @returns
 */
export function postSettlementSupplierServiceSellSkuList(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/settlement/supplier/serviceSell/sku/list`,
    data: params,
  });
}

/**
 * 总控-平台销售服务费明细列表
 * @param {*} params
 * @returns
 */
export function postSettlementSupplierServiceSellOrderList(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/settlement/supplier/serviceSell/order/list`,
    data: params,
  });
}
